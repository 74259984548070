@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.notifications__mark-all {
  cursor: pointer;
  color: var(--v-primary-base);
}

.notifications__mark-all:hover {
  text-decoration: underline;
}

.notification-icon:hover {
  color: var(--v-icon-base);
  cursor: pointer;
}

.notification .v-badge__wrapper .v-badge__badge {
  padding: 3px;
}
