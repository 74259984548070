@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.v-chip {
  height: 20px;
  font-size: 10px;
  font-weight: 700;
}

.v-chip ::v-deep {
  &[dense] {
    padding: 0 6px;
    border-radius: 4px;
  }

  &[rounded] {
    border-radius: var(--pied-border-base);
  }
}
