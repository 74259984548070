@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.icon-style {
  border: solid var(--v-button-base) 1px;
  border-radius: 50%;
  color: var(--v-button-base);
  background-color: white;
  cursor: pointer;
  position: absolute;
}

.icon-style__position-expanded-menu {
  left: 237px;
}

.icon-style__position-mini-menu {
  left: 65px;
}

.icon-style-mobile__position-expanded-appbar {
  left: 237px;
}

.icon-style:hover {
  opacity: 0.75;
}

.icon-style__sticky {
  position: sticky;
  top: 30px;
  z-index: 7;
}
