@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

@import "@/scss/nav-bar.scss";

::v-deep .v-list-item__title {
  white-space: normal;
}

.v-navigation-drawer--mini-variant {
  .nav-bar-header-logo {
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 28px;
    height: 108px;
  }
}

.nav-var__footer_container {
  content: "";
  height: 10px;
  background: $distributor-gradient;
}

.navbar__preview {
  ::v-deep .v-navigation-drawer__content {
    overflow: hidden;
  }
}

.v-footer {
  font-size: 0.7rem;
  justify-content: center;
}

.v-chip.isNew ::v-deep {
  padding: 0 6px;
  border-radius: 4px;
  font-size: 8px;
  font-weight: 600;
}
