$nav-bar-font-size: 14px;
$distributor-gradient: linear-gradient(
  0.25turn,
  var(--v-colorOneLinearGradient-base),
  var(--v-colorTwoLinearGradient-base)
);

.main-nav-bar {
  .v-list {
    padding-right: 20px !important;
  }

  .v-divider {
    border-color: var(--v-secondary-base) !important;
    margin: 8px 0 8px 20px;
  }

  .v-list-item--link:before {
    background: $distributor-gradient;
  }

  .v-ripple__container {
    display: none;
  }

  .v-list-item--active {
    &::before {
      opacity: 1 !important;
    }

    .v-list-item__title {
      color: white;
    }

    .v-icon {
      color: white !important;
    }
  }

  .v-list-item {
    font-size: $nav-bar-font-size;
    height: 40px;
    min-height: 40px;
  }

  .v-list-item__icon {
    margin: 13px 6px 13px 0 !important;
    justify-content: center;
  }

  .v-list-item__title {
    font-size: $nav-bar-font-size;
    color: #494949;
    z-index: 2;
  }

  .v-list-group__header__append-icon {
    margin-left: 0 !important;

    i {
      rotate: -90deg;
      font-size: $nav-bar-font-size;
    }
  }

  .v-list-item--active {
    .v-list-group__header__append-icon {
      i {
        transform: rotate(90deg) !important;
      }
    }
  }

  .v-list-group--no-action > .v-list-group__items {
    margin-top: 6px;

    > .v-list-item {
      padding-left: 14px;
      margin-left: 34px;
      border-left: 1px solid #d8d8d8;
      padding-right: 0;

      &:hover {
        border-left: 2px solid var(--v-secondary-base);
      }
    }

    > .v-list-item--active {
      border-left: 2px solid var(--v-secondary-base);

      .v-list-item__title {
        color: #494949;
      }

      &:before {
        background: var(--v-secondary-base);
        opacity: 0.35 !important;
      }
    }
  }

  .v-list-group__header {
    &:before {
      background: none;
    }

    .v-list-item__title {
      color: #494949;
    }

    .v-icon {
      color: var(--v-secondary-base) !important;
    }
  }

  &.v-navigation-drawer--mini-variant {
    .v-list {
      padding-right: 6px !important;
    }

    .v-list-item__icon {
      margin-right: 0 !important;
    }

    .v-divider {
      margin: 8px;
    }
  }
}
