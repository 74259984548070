.reverse-switch .v-input__slot {
  flex-direction: row-reverse;
  justify-content: flex-end;

  .v-label {
    display: block;
    flex: 1;
  }

  .v-application--is-ltr & {
    .v-input--selection-controls__input {
      margin-right: 0;
      margin-left: 8px;
    }
  }

  .v-application--is-rtl & {
    .v-input--selection-controls__input {
      margin-left: 0;
      margin-right: 8px;
    }
  }
}
