.list__title {
  color: #1d0f29;
  min-height: 30px;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 620px) {
    font-size: 14px;
  }
}
