@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

::v-deep .v-dialog {
  border-radius: 12px;
}

.pied-dialog__title {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  word-break: break-word;
  margin-right: 10px;
  font-size: 18px;
}

.pied-dialog__subtitle {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
}

.pied-dialog__body {
  color: #2f353999 !important;
  text-align: center;
  padding-bottom: 28px !important;
  padding-top: 20px !important;

  &[no-padding] {
    padding: 0 !important;
  }

  &[dense] {
    padding: 12px !important;
  }

  &[data-clean-head="true"] {
    padding-top: 0 !important;
  }

  @media (max-width: 800px) {
    padding: 12px !important;
  }
}

.pied-dialog.has-subtitle {
  .pied-dialog__close-btn {
    top: -14px;
  }
}

.pied-dialog__close-btn {
  color: #424259 !important;
  position: relative;
  right: 8px;
  top: 0;
}

.pied-dialog__actions {
  justify-content: center;
  padding: 16px !important;
  gap: 16px;

  ::v-deep > div.double-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > button {
      width: 48% !important;
      height: 38px !important;
    }

    @media (max-width: 600px) {
      flex-direction: column;
      height: 100px;
      > button {
        width: 100% !important;

        &:last-child {
          margin-top: 8px;
        }
      }
    }
  }

  ::v-deep button {
    width: 195px !important;
    height: 40px !important;
    margin-left: 0 !important;

    .v-btn__content {
      font-size: 14px;
    }

    @media (max-width: 600px) {
      display: flex;
      flex: 1 !important;
    }
  }
}
