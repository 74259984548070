@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.pied-breadcrumb__divisor {
  margin: 0px 1px 0px 2px;
  padding-top: 5px;
}
.pied-breadcrumb-current-text__color {
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
}
.pied-breadcrumb-father-text__color {
  color: rgba(0, 0, 0, 0.5);
}
.pied-breadcrumbs__text-container {
  display: inline-flex;
  align-content: center;
  align-items: baseline;
}

.pied-breadcrumbs__text {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

.pied-breadcrumbs__text-hover:hover {
  color: var(--v-button-base) !important;
  opacity: 1;
  cursor: pointer;
}

.pied-breadcrumbs__title {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.92px;
  text-align: left;
  margin-top: 5px;
}
