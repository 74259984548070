@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.nps-dialog-research__title {
  font-size: 16px;
  font-weight: 600;
  line-height: 15px;
  text-align: left;
  color: #0b0b0b;
}

.nps-dialog-research__vote-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;

  &[data-has-selection="true"] {
    div {
      opacity: 45%;
    }
  }

  div {
    height: 45px;
    width: 45px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 3px;
    cursor: pointer;
    font-size: 22px;
    font-weight: 700;
    line-height: 22px;

    &[data-selected] {
      cursor: auto;
      opacity: 100%;
    }
  }
}

.nps-dialog-research__subtitle {
  text-align: left;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  margin-bottom: 10px;

  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
  }
}

.nps-dialog-research__container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 0 29px;

  div {
    width: 100%;
  }
}

.nps-dialog-research__vote-h1 {
  font-size: 32px;
  font-weight: 600;
  line-height: 50px;
  color: black;
}

.nps-dialog-research__caption {
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  color: black;
}
