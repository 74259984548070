.v-data-table {
  .v-data-table-header th {
    font-weight: 500;
    font-size: 16px !important;
    line-height: 19px;
    color: #1d0f29 !important;
  }

  th:first-of-type {
    border-top-left-radius: 6px;
  }

  th:last-of-type {
    border-top-right-radius: 6px;
  }

  .v-data-table__mobile-row__cell {
    align-content: center;
  }
}

.pied-data-table {
  &[scrollable="true"] {
    height: calc(100vh - 186px);
  }

  &[scrollable="false"] {
    height: auto;
  }

  &[fixed="true"] {
    height: 60vh !important;
  }

  &[readonly="readonly"] {
    tr {
      td {
        pointer-events: none !important;
        &:first-child {
          pointer-events: auto !important;
        }
      }
    }
  }

  table {
    border-collapse: separate;
  }

  border: 1px solid #0000001a;
  box-shadow: 0 1px 2px 0 #1018280f !important;
  border-radius: 12px !important;
  overflow: hidden !important;

  .v-data-table__wrapper {
    height: 100%;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 0;
      height: 4px !important;
      background: transparent;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: var(--pied-white-base);
    }
  }

  thead {
    tr {
      th {
        padding: 0 8px !important;
        background-color: var(--pied-white-base) !important;
        > span {
          font-size: 12px;
          font-weight: 600;
          color: var(--pied-text-color-secondary);
        }
        &[role="columnheader"].fixed {
          position: sticky;
          right: 0;
          z-index: 2;
          //border-left: 1px solid rgba(0, 0, 0, .13);
        }
        &:last-child {
          padding: 0 !important;
          margin: 0 !important;
        }
      }
    }
  }

  tbody {
    td {
      font-weight: 400;
      font-size: 12px !important;
      color: var(--pied-text-color-primary) !important;
      padding: 0 8px !important;
    }
    td.fixed {
      position: sticky;
      right: 0;
      z-index: 1;
      background-color: white;
      border: 0;
      outline: 0;
      border-left-width: 1px;
      border-left-color: rgb(0, 0, 0, 0.13);
      border-left-style: solid;
      width: 40px !important;
      padding: 0 !important;
    }
    tr {
      min-height: 64px !important;
    }
    .v-data-table__checkbox {
      width: 25px;
    }
  }
}

.pied-menu--actions {
  padding: 0;
  //background: var(--pied-white-base) !important;

  .v-list-item {
    height: 36px;
    min-height: 36px;
    width: 100%;
    //background: white !important;
    //&:first-child {
    //  border-top-left-radius: 4px !important;
    //  border-top-right-radius: 4px !important;
    //}
    //&:last-child {
    //  border-bottom-left-radius: 4px !important;
    //  border-bottom-right-radius: 4px !important;
    //}
    .v-list-item__content {
      .v-list-item__title {
        text-align: start;
      }
    }
  }
}
