@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.app-bar__pro-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 21px;
  border: 1px solid var(--v-button-base);
  border-radius: 15px;
  background-color: transparent;
  color: var(--v-button-base);
}

.app-bar__pro-icon-text {
  font-size: 10px;
  font-weight: 600;
  text-align: center;
}

::v-deep .v-toolbar__content > .v-btn.v-btn--icon:last-child {
  margin-right: 0 !important;
}

.whatsapp:hover {
  color: #25d366;
}

.whatsapp {
  color: #075e54 !important;
}
