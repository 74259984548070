@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.nav-bar-header-logo {
  content: "";
  display: flex;
  justify-content: center;
  height: 166px;
  max-width: 210px;
  margin: 0 20px 32px 20px;
  padding: 16px;
  border-radius: 0 0 10px 10px;
}
