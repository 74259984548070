@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.notify-dialog__actions {
  display: flex;
  gap: 6px;

  button {
    flex: 1;
  }

  ::v-deep .v-btn__content {
    font-weight: inherit;
  }
}

.notify-dialog__message {
  font-weight: 400;
  font-size: 14px;
  color: #2f353999;
  margin: 16px 0;
}
