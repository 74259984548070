.v-application--wrap {
  overflow: hidden;
}

.v-main {
  background: #f8f9fa;
}

.container {
  max-width: 100% !important;
}

.v-enter,
.v-leave-to {
  opacity: 0;
}

.v-enter {
  transform: translate3d(0, -20px, 0);
}

.v-leave-to {
  transform: translate3d(0, 20px, 0);
}

.v-input__slot {
  font-size: 0.9rem;
}

.v-label {
  font-size: 0.9rem !important;
}

.v-btn:not(.v-btn--round):not(.v-btn--rounded) {
  border-radius: 8px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;

  &:not(.v-btn--round).v-size--default {
    height: 38px;
  }
}

.v-btn__content {
  font-size: 0.7rem;
  font-weight: normal;
}

.v-data-table-header-mobile {
  display: none;
}

.v-data-table tbody tr:hover:not(.v-data-table__expanded__content) {
  background: color-mix(
    in srgb,
    var(--v-primary-base),
    transparent 90%
  ) !important;
}

.v-pagination__item {
  outline: none !important;
}

.v-icon.v-icon::after {
  background: transparent;
}

.v-data-table__mobile-row__header {
  font-size: 0.72rem;
  letter-spacing: 1.3px;
  text-transform: uppercase;
}

.v-data-table__selected {
  background: color-mix(
    in srgb,
    var(--v-primary-base),
    transparent 90%
  ) !important;
}

.v-pagination__item--active {
  color: var(--v-orange2_-base) !important;
}

.v-pagination {
  font-weight: 500;
}
