:root {
  --pied-white-base: #e9ecef;
  --pied-text-color-primary: rgba(0, 0, 0, 0.85);
  --pied-text-color-secondary: rgba(0, 0, 0, 0.5);
  --pied-border-base: 16px;
  --pied-border-color: rgba(0, 0, 0, 0.13);
}

.required:after {
  content: "*";
  color: #d80027;
  font-weight: 400;
}

.decrease-1 {
  transform: scale(0.9);
}

.decrease-2 {
  transform: scale(0.8);
}

.iconColor .v-icon {
  color: var(--v-black-base) !important;
}

.alignVertical {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-ellipse {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.no-pointer {
  cursor: auto;
}

.no-pointer.v-card--link:before {
  background: none;
}

.borderless {
  border: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.dot-separator {
  height: 0;
  padding: 0 0.2rem;
  &.dot-separator-lg {
    padding: 0 1rem;
  }
  &.dot-separator-xs {
    padding: 0 0.1rem;
  }
  &:before {
    content: "\2022";
    color: rgba(0, 0, 0, 0.5);
  }
}

.pied-inputbase {
  .v-input.error--text {
    fieldset {
      border-color: var(--v-error-base) !important;
    }
  }
}

.pied-text-h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  color: var(--pied-text-color-primary);
}

.pied-text-h5 {
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  color: var(--pied-text-color-primary);
}
