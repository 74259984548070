.solutionText {
  font-size: 0.8rem;
  color: #0f0f29;
}

.fontUpper {
  font-size: 0.8rem;
  letter-spacing: 1.3px;
  text-transform: uppercase;
}

.fontUpper2 {
  font-size: 0.72rem;
  letter-spacing: 1.3px;
  text-transform: uppercase;
}

.fontH2 {
  font-size: 1.3rem;
  line-height: 1.4rem;
  letter-spacing: 1.2px;
}

.fontH3 {
  font-size: 1.7rem;
  letter-spacing: 1.4px;
}

.fontH4 {
  font-size: 1.2rem;
  line-height: 1.4rem;
  letter-spacing: 0.6px;
}

.fontH5 {
  font-size: 0.9rem;
  line-height: 1.4rem;
  letter-spacing: 0.2px;
}

.fontContent2 {
  font-size: 0.85rem;
  letter-spacing: 0.6px;

  @media (max-width: 769px) {
    font-size: 0.75rem;
  }
}

.fontContent2c {
  font-size: 1rem;
  letter-spacing: 0.6px;

  @media (max-width: 769px) {
    font-size: 0.8rem;
  }
}

.fontContent3 {
  font-size: 0.7rem;
  letter-spacing: 0.6px;
}

.fontContent4 {
  font-size: 0.6rem;
  letter-spacing: 0.6px;
}

.pied-title {
  color: #1d0f29;
  font-weight: 600;
  font-size: 18px;
  padding-bottom: 0;
}

.pied-subtitle {
  color: #1d0f29;
  font-weight: 500;
  font-size: 14px;
  padding-bottom: 0;
}

.main_color {
  color: var(--v-textColor-base);
  caret-color: var(--v-textColor-base);
}
