//noinspection CssUnknownTarget
@import url("https://fonts.googleapis.com/css?family=Montserrat|Oswald|Roboto|Kanit");

* {
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Montserrat, Oswald, sans-serif;
}

::-webkit-scrollbar {
  height: 7px;
  width: 7px;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  border-radius: 7px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background-color: darkgrey;
}

html {
  overflow-y: auto !important;
}
